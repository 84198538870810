<template>
	<div class="d-flex flex-column flex-root">
		<div
			class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
			id="kt_login"
		>
			<!--begin::Aside-->
			<div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #f2c98a">
				<div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
					<a href="#" class="text-center mb-10">
						<img src="media/logos/logo-genic.png" class="max-h-70px" alt="" />
					</a>
					<h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #986923">
						Discover Amazing Genic Drive <br />with great build tools
					</h3>
				</div>
				<div
					class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
					:style="{ backgroundImage: `url(${backgroundImage})` }"
				></div>
			</div>
			<!--begin::Aside-->
			<!--begin::Content-->
			<div
				v-if="isPageLoaded"
				class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
			>
				<div class="d-flex flex-column-fluid flex-center">
					<!--begin::Signin-->
					<div v-if="isPageExpired">
						<div class="pb-13 pt-lg-0 pt-5">
							<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
								Oopss... Link Expired
							</h3>
							<p class="mt-5 text-muted font-weight-bold font-size-h4 ">
								To reset your password, return to the login page and select "<span class="text-primary text-hover-primary font-weight-bolder">Forgot Password ?</span>" to send a new email.
							</p>
						</div>
					</div>
					<div v-else class="login-form login-signin">
						<form class="form" novalidate="novalidate" id="kt_login_signin_form">
							<div class="pb-13 pt-lg-0 pt-5">
								<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
									Create New Password
								</h3>
							</div>
							<div class="form-group">
								<label class="font-size-h6 font-weight-bolder text-dark">Email</label>
								<div id="example-input-group-1" label="" label-for="example-input-1">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="text"
										name="email"
										ref="email"
										readonly
										:disabled="isLoading"
										v-model="dbUser.email"
									/>
								</div>
							</div>
							<div class="form-group">
								<div class="d-flex justify-content-between mt-n5">
									<label class="font-size-h6 font-weight-bolder text-dark pt-5">New Password</label>
								</div>
								<div id="example-input-group-2" label="" label-for="example-input-2">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="password"
										name="password"
										ref="password"
										:disabled="isLoading"
										v-model="forgotPassword"
										autocomplete="off"
									/>
								</div>
							</div>
							<div class="form-group">
								<div class="d-flex justify-content-between mt-n5">
									<label class="font-size-h6 font-weight-bolder text-dark pt-5">Confirm Password</label>
								</div>
								<div id="example-input-group-2" label="" label-for="example-input-2">
									<input
										class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
										type="password"
										name="password"
										ref="password"
										:disabled="isLoading"
										v-model="forgotConfirmPassword"
										autocomplete="off"
										v-on:keyup.enter="submitLogin()"
									/>
								</div>
							</div>
							<div class="pb-lg-0 pb-5">
								<button
									:disabled="isLoading"
									type="button"
									v-on:click="submitLogin()"
									ref="kt_login_signin_submit"
									class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
								>
									Reset Password
								</button>
							</div>
						</form>
					</div>
					<!--end::Signin-->
				</div>
				<!--begin::Content footer-->
				<div
					class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
				>
					<a :href="`https://www.genicsolutions.com/privacy-policy?utm=genic-drive-reset-password-page&cdx=${$APP_CLIENT_NAME}`" target="_blank" class="text-primary font-weight-bolder font-size-h5">Privacy Policy</a>
					<a :href="`https://www.genicsolutions.com/contact-us?utm=genic-drive-reset-password-page&cdx=${$APP_CLIENT_NAME}`" target="_blank" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
				</div>
				<!--end::Content footer-->
			</div>
			<div class="btx-alert" v-if="errors.length">
				<div
					v-for="(row, index) in errors"
					:key="index"
					class="alert alert-custom alert-notice alert-light-danger fade show"
					role="alert"
				>
					<div class="alert-icon">
						<i class="flaticon-warning"></i>
					</div>
					<div class="alert-text">{{ row }}</div>
					<div class="alert-close">
						<button
							type="button"
							v-on:click="removeError(index)"
							class="close"
							data-dismiss="alert"
							aria-label="Close"
						>
							<span aria-hidden="true"><i class="ki ki-close"></i></span>
						</button>
					</div>
				</div>
			</div>
			<!--end::Content-->
		</div>
	</div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";

export default {
	name: "login-1",
	data() {
		return {
			isLoading: false,
			isPageLoaded: false,
			isPageExpired: false,
			errors: [],
			forgotEmail: null,
			forgotPassword: null,
			forgotConfirmPassword: null,
			dbUser: {},
			token: null
		};
	},
	computed: {
		backgroundImage() {
			return process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg";
		},
	},
	methods: {
		removeError(index) {
			this.errors.splice(index, 1);
		},
		getUser() {
			this.$store.dispatch(QUERY, {url: 'auth/forgot', data: { ...this.$route.query }})
				.then(({token, user}) => {
					this.dbUser = user;
					this.token = token;
					this.isPageLoaded = true;
					this.isPageExpired = false;
				}).catch(() => {	
					this.isPageExpired = true;
					this.isPageLoaded = true;
				});
		},
		submitLogin() {

			this.errors = [];

			if (this.forgotPassword !== this.forgotConfirmPassword) {
				this.errors.push('Password and confirm password does not match.');
				return false;
			}

			const submitButton = this.$refs["kt_login_signin_submit"];

			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			this.isLoading = true;

			this.$store.dispatch(PATCH, {
				url: 'auth/forgot',
				data: {
					...this.$route.query,
					'password': this.forgotPassword,
					'password_confirmation': this.forgotConfirmPassword,
					'oauth-token': this.token
				}
			}).then(() => {
				const resolved = this.$router.resolve({ name: "login", query: { email: this.dbUser.email, oauthx: 'success' } });
				window.location.href = resolved.href;
			})
			.catch(({ data }) => {
				this.errors = data.data;
				this.isLoading = false;
				submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
			});
		},
	},
	mounted() {
		this.getUser();
	}
};
</script>
